/* This file contains all the constants for colors and font styles */

$body-font: "Gelasio", Georgia, serif;
// Note that Gill Sans is the top of the stack and corresponds to what is used in Tufte's books
// However, it is not a free font, so if it is not present on the computer that is viewing the webpage
// The free Google 'Lato' font is used instead. It is similar.
$font-font-awesome: "FontAwesome";
$quote-font: "Open Sans","Spartan", Calibri, sans-serif;
$sans-font: "Merriweather","Sen","Spartan", Calibri, sans-serif;
$sans-cap-font: "Spartan", "Josefin Sans", Calibri, sans-serif;
$code-font: Consolas, "Liberation Mono", Menlo, Courier, monospace;
$url-font: "Lucida Console", "Lucida Sans Typewriter", Monaco, "Bitstream Vera Sans Mono", monospace;
$text-color: #111;
$bg-color: #fffffd;
$contrast-color: #2b3088;
$link-color: #8dd4e1;
$border-color: #333333;
$link-style: underline; // choices are 'color' or 'underline'. Default is color using $contrast-color set above



